<template>
  <div class="tw-relative">
    <i
      v-if="loading"
      class="fas fa-spinner-third fa-spin tw-absolute tw-text-3xl tw-top-1/2 tw-left-1/2"
    />
    <DatePicker
      v-model="date"
      v-bind="$attrs"
      name="visitDate"
      :language="nl"
      :full-month-name="true"
      :monday-first="true"
      :disabled-dates="disabledDates"
      :class="{ 'tw-opacity-40 tw-pointer-events-none': loading }"
      v-on="$listeners"
    >
      <span slot="prevIntervalBtn">
        <i class="fa fa-chevron-left tw-w-full tw-text-right" />
      </span>
      <span slot="nextIntervalBtn">
        <i class="fa fa-chevron-right" />
      </span>
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'Calendar',
  components: {
    DatePicker
  },
  // To remove any direct inheritance to root element magic, inheritAttrs: false and v-bind="$attrs" ensures that the attributes get bound to the right element.
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Date
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      nl,
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)) // All dates before today
      }
    }
  },
  computed: {
    date: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style scoped>
@import '~@/styles/new/calendar.css';
</style>
